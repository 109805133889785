<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>
      Yoga-Programme für deine persönlichen Ziele
    </ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
    <ng-container i18n>
      Du möchtest eine Yoga-Morgenroutine aufbauen, mit dem Meditieren beginnen, Stress abbauen oder deine Rückenschmerzen lindern? Wähle aus
      über 50 Programmen jederzeit das passende für dich.
    </ng-container>
  </div>

  <owl-carousel-o class="has-dots-under" [options]="autoplay">
    <ng-container *ngFor="let teaser of programTeasers">
      <ng-template carouselSlide>
        <div class="is-flex is-justify-content-center">
          <a [href]="teaser.path">
            <!-- width and class have to be included both to overwrite .owl-item img -->
            <img [ngSrc]="teaser.mobile" width="343" height="343" [alt]="teaser.title"
              class="img-mobile has-border-radius-5 is-width-343px" />
            <img [ngSrc]="teaser.tablet" width="730" height="450" [alt]="teaser.title"
              class="img-tablet-only has-border-radius-5 is-width-1170px" />
            <img [ngSrc]="teaser.desktop" width="1170" height="418" [alt]="teaser.title" class="img-desktop has-border-radius-5" />
          </a>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <!-- make space for the dots -->
  <div class="mb-5">&nbsp;</div>
</section>
