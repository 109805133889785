import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonType = 'primary' | 'secondary' | 'tertiary';
export type ButtonSize = 'small' | 'medium' | 'large' | 'xl' | 'xxl';
export type ButtonColor = 'default' | 'error' | 'success';

/**
 * Usage
 * <app-button type="secondary" [disabled]="isDisabled" size="medium" color="success" (onClick)="doSomething()">
 *   <svg-arrow-back slot="icon-left"></svg-arrow-back>
 *   Do it!
 *   <svg-arrow slot="icon-right"></svg-arrow>
 * </app-button>
 */
@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
})
export class ButtonComponent {
  @Input() href?: string;
  // TODO: add router link
  @Input() type?: ButtonType = 'primary';
  @Input() disabled?: boolean = false;
  @Input() size?: ButtonSize = 'large';
  @Input() color?: ButtonColor = 'default';
  // TODO: get rid of usage, this goes agains Single Responsibility Principle
  @Input() usage = '';
  // TODO: add sizes xl and xxl
  // TODO: add dark mode

  @Output() onClick = new EventEmitter<MouseEvent>();

  checkClick(event: MouseEvent) {
    if (!this.disabled) this.onClick.emit(event);
  }
}
