import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { Viewport } from 'src/app/core/core.state';

@Component({
  selector: 'app-videos-section',
  templateUrl: './videos-section.component.html',
  styleUrls: ['./videos-section.component.sass'],
})
export class VideosSectionComponent implements OnInit {
  public viewport$: Observable<Viewport>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.viewport$ = this.store.select(state => state.core.viewport);
  }

  public videoTeasers = [
    {
      id: 21252,
      slug: 'sanfter-morgen-flow-mit-wanda',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/i377_212_wanda-badwal-sanfter-morgen-flow-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/i377_212_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/thumb_wanda-badwal-sanfter-morgen-flow-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/thumb_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/big_wanda-badwal-sanfter-morgen-flow-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/fill_873_491_wanda-badwal-sanfter-morgen-flow-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21252/fill_873_491_webp_wanda-badwal-sanfter-morgen-flow-still.jpg.webp',
      },
      title: 'Sanfter Morgen-Flow mit Wanda',
      teacher: 'Wanda Badwal',
      published_at: '2024-04-25T05:00:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger',
        },
      ],
      rating_count: 691,
      rating: 5.0,
      comment_count: 70,
      style: 'Hatha',
      effort: 3,
      path: '/videos/sanfter-morgen-flow-mit-wanda',
      duration_in_words: '24 Min.',
      short_description:
        'Du bringst Länge in deinen Rücken und Weite in deinen Herzraum.',
      is_new: false,
      image_alt: 'Wanda Badwal sanfter Morgen-Flow',
    },
    {
      id: 3683,
      slug: 'tutorial-box-breath-fuer-mehr-fokus',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/webp_box_breath_tutorial_still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/i377_212_box_breath_tutorial_still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/i377_212_webp_box_breath_tutorial_still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/thumb_box_breath_tutorial_still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/thumb_webp_box_breath_tutorial_still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/big_box_breath_tutorial_still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/fill_873_491_box_breath_tutorial_still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3683/fill_873_491_webp_box_breath_tutorial_still.jpg.webp',
      },
      title: 'Tutorial: Box Breath für mehr Fokus',
      teacher: 'Tina Scheid',
      published_at: '2022-01-31T05:05:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'Für alle',
        },
      ],
      rating_count: 328,
      rating: 5.0,
      comment_count: 13,
      style: 'Atemübungen',
      effort: 0,
      path: '/videos/tutorial-box-breath-fuer-mehr-fokus',
      duration_in_words: '1 Min.',
      short_description:
        'Tina Scheid zeigt dir die Box-Atmung (Box Breath), die dir hilft, wenn du im Alltag mal unkonzentriert bist und dich wieder neu fokussieren möchtest. ',
      is_new: false,
      image_alt: 'Box Breath für mehr Fokus – Tina Scheid',
    },
    {
      id: 4392,
      slug: 'mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/big_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
      },
      title: 'Mit Rückbeugen angstfrei nach vorne schauen',
      teacher: 'Moritz Ulrich',
      published_at: '2022-11-17T05:14:00.000+01:00',
      levels: [
        {
          number: 2,
          label: 'Mittelstufe',
        },
      ],
      rating_count: 434,
      rating: 5.0,
      comment_count: 76,
      style: 'Jivamukti',
      effort: 4,
      path: '/videos/mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      duration_in_words: '43 Min.',
      short_description:
        'Eine Allround-Sequenz mit Fokus auf Rückbeugen, die dich unterstützen können, bedingungslos zu lieben und dich lieben zu lassen.',
      is_new: false,
      image_alt: 'Mit Rückbeugen angstfrei nach vorne schauen – Moritz Ulrich',
    },
    {
      id: 3840,
      slug: 'yoga-gegen-aengste-und-stress',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/webp_yoga_angst_stress_still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_yoga_angst_stress_still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_webp_yoga_angst_stress_still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_yoga_angst_stress_still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_webp_yoga_angst_stress_still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/big_yoga_angst_stress_still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_yoga_angst_stress_still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_webp_yoga_angst_stress_still.jpg.webp',
      },
      title: 'Yoga gegen Ängste und Stress',
      teacher: 'Gabriela Bozic',
      published_at: '2022-03-31T05:05:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger',
        },
      ],
      rating_count: 536,
      rating: 4.5,
      comment_count: 109,
      style: 'Vinyasa Flow / Power Yoga',
      effort: 2,
      path: '/videos/yoga-gegen-aengste-und-stress',
      duration_in_words: '37 Min.',
      short_description:
        'Verschiedene Seitdehnungen und Vorbeugen geben dir mehr Erdung, Zuversicht und Ruhe. Die Praxis gibt dir das Gefühl des inneren Friedens zurück.',
      is_new: false,
      image_alt: 'yoga_gegen_angst_stress',
    },
  ] as Array<VideoTeaser>;
}
