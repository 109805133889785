<div class="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center">
  <img ngSrc="production/uploads/components/recommended-by/happy-way.png" alt="Happy Way Logo" width="115" height="21" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/stern.png" alt="Stern Logo" width="91" height="32" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/yoga-aktuell.png" alt="Yoga Aktuell Logo" width="68" height="21"
    class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/brigitte.png" alt="Brigitte Logo" width="93" height="26" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/cosmopolitan.png" alt="Cosmopolitan Logo" width="117" height="22"
    class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/gala.png" alt="Gala Logo" width="43" height="19" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/vogue.png" alt="Vogue Logo" width="81" height="21" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/focus.png" alt="Focus Logo" width="102" height="27" class="mx-4 my-3" />
</div>