import { Environment } from './environment.class';

export const environment: Environment = {
  apiPath: '/api/web',
  appName: 'yogaeasy-frontend',
  facebookAppId: 2023838527897517,
  facebookAppSecret: '9067c6bfc62e4e020a39e8bb4bf6f4ef',
  featureToggles: {},
  googleAnalyticsPropertyId: 'UA-12113470-10',
  mobileAppLinkAndroid:
    'https://app.adjust.com/m9qzq6?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dde.yogaeasy.videoapp%26hl%3Dde',
  mobileAppLinkIos:
    'https://app.adjust.com/8a0xrp?redirect=https%3A%2F%2Fapps.apple.com%2Fde%2Fapp%2Fyogaeasy-online-yoga-studio%2Fid692200180',
  mobileAppLinkAndroidMobileMenu:
    'https://app.adjust.com/jj9me5?fallback=https://play.google.com/store/apps/details?id=de.yogaeasy.videoapp',
  mobileAppLinkIosMobileMenu:
    'https://app.adjust.com/3g7ue2?fallback=https://itunes.apple.com/de/app/yogaeasy-yoga-video-app/id692200180',
  name: 'staging',
  production: false,
  tagManagerId: 'GTM-NMDM6H',
  trackingMixpanelToken: '687e44689457e0d7422f8d0ba33bb36f',
  trackingMixpanelApiKey: 'e0ac584a625f12bcb548b188fbbd8af7',
};
