import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LiveClassesBanner } from 'src/app/api/models/live-classes-banner';
import { Utility } from 'src/app/shared/utility';

type ImageUrls = { mobile: string; tablet: string; desktop: string };

@Component({
  selector: 'app-live-section',
  templateUrl: './live-section.component.html',
  styleUrls: ['./live-section.component.sass'],
})
export class LiveSectionComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public liveClassesBanner: LiveClassesBanner;
  public isExternalLink: boolean;
  public bannerButtonClass: string;
  public imageUrls: ImageUrls;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.homepage.params)
      .pipe(
        takeUntil(this.destroy$),
        filter((homeParams) => !!homeParams),
      )
      .subscribe((homeParams) => {
        this.liveClassesBanner = homeParams.live_classes_banner;
        this.imageUrls = this.getRelativeImageUrls(this.liveClassesBanner);
        this.isExternalLink = Utility.isExternalLink(
          this.liveClassesBanner.button_link,
        );
        this.bannerButtonClass = this.mapBannerButtonClass(
          this.liveClassesBanner.button_style,
        );
      });
  }

  private mapBannerButtonClass(selectedStyle): string {
    let mappedClass = selectedStyle;

    switch (selectedStyle) {
      case 'primary':
        mappedClass = 'is-primary';
        break;
      case 'secondary':
        mappedClass = 'is-secondary';
        break;
    }
    return mappedClass;
  }

  private getRelativeImageUrls(
    liveClassesBanner: LiveClassesBanner,
  ): ImageUrls {
    return {
      mobile: Utility.getRelativePath(liveClassesBanner.mobile_url),
      tablet: Utility.getRelativePath(liveClassesBanner.tablet_url),
      desktop: Utility.getRelativePath(liveClassesBanner.desktop_url),
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
