<button
  *ngIf="!href"
  class="ye-button"
  [ngClass]="'ye-button-' + type + ' ye-button-' + size + ' ye-button-' + color + ' ' + usage"
  [disabled]="disabled"
  (click)="checkClick($event)"
>
  <ng-template *ngTemplateOutlet="content"></ng-template>
</button>

<a
  *ngIf="href"
  [href]="href"
  class="ye-button no-link"
  [ngClass]="'ye-button-' + type + ' ye-button-' + size + ' ye-button-' + color + ' ' + usage + (disabled ? ' disabled' : '')"
  (click)="checkClick($event)"
>
  <ng-template *ngTemplateOutlet="content"></ng-template>
</a>

<ng-template #content>
  <span class="icon-left">
    <ng-content select="[slot=icon-left]"></ng-content>
  </span>
  <ng-content></ng-content>
  <span class="icon-right">
    <ng-content select="[slot=icon-right]" class="icon-right"></ng-content>
  </span>
</ng-template>
