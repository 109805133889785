<nav role="navigation" class="mobile-navigation p-6 is-flex is-flex-direction-column is-align-items-center"
  [ngClass]="{ open: (mobileNavigationOpen$ | async) }">
  <button class="button is-primary is-inverted has-text-kokoda is-absolute-top-right" (click)="closeNav()">
    <svg-exit class="icon"></svg-exit>
  </button>
  <a routerLink="/" class="ye-logo" (click)="closeNav()">
    <svg-ye-logo-light class="ye-logo"></svg-ye-logo-light>
  </a>
  <ul class="mt-5 is-width-100 has-max-width has-bordered-children">
    <li>
      <a routerLink="/video-kategorien" class="is-block p-4 is-flex is-align-items-center has-text-primary" (click)="closeNav()">
        <svg-play class="icon has-text-kokoda"></svg-play>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>Videos</span>
      </a>
    </li>
    <li>
      <a routerLink="/programme" class="is-block p-4 is-flex is-align-items-center has-text-primary" (click)="closeNav()">
        <svg-calendar class="icon is-smaller has-text-kokoda"></svg-calendar>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>Programme</span>
      </a>
    </li>
    <li>
      <a routerLink="/live-klassen" routerLinkActive="has-text-kokoda" class="is-block p-4 is-flex is-align-items-center has-text-primary"
        (click)="closeNav()">
        <svg-video class="icon has-text-kokoda"></svg-video>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>Live</span>
      </a>
    </li>
    <li>
      <a routerLink="/yogalehrer" routerLinkActive="has-text-kokoda" class="is-block p-4 is-flex is-align-items-center has-text-primary"
        (click)="closeNav()">
        <svg-yoga class="icon has-text-kokoda"></svg-yoga>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>Lehrer:innen</span>
      </a>
    </li>
    <li>
      <a routerLink="/magazin" routerLinkActive="has-text-kokoda" class="is-block p-4 is-flex is-align-items-center has-text-primary"
        (click)="closeNav()">
        <svg-notebook class="icon has-text-kokoda"></svg-notebook>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>Yoga-Magazin</span>
      </a>
    </li>
    <li>
      <a href="https://academy.yogaeasy.de/" target="_blank" rel="noopener"
        class="is-block p-4 is-flex is-align-items-center has-text-primary" (click)="closeNav()">
        <svg-education class="icon has-text-kokoda"></svg-education>
        <span class="is-uppercase is-size-5-and-half has-text-weight-semibold ml-4" i18n>
          Academy
        </span>
        <svg-external-link class="icon is-tiny ml-1"></svg-external-link>
      </a>
    </li>
  </ul>
  <div class="mb-4 mt-6" i18n>Nutze auch die YogaEasy-App:</div>
  <app-appstore-buttons [inMobileMenu]="true" class="has-text-centered" />
</nav>