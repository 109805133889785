import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorResponse } from '../models/error-response';
import { JoinProgramResponse } from '../models/join-program-response';
import { LeaveOrUnsubscribeProgramResponse } from '../models/leave-or-unsubscribe-program-response';
import { Program } from '../models/program';
import { ProgramDetails } from '../models/program-details';
import { Utility } from 'src/app/shared/utility';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  path = `${environment.apiPath}/v1/programs`;

  constructor(private httpClient: HttpClient) {}

  fetchAll(limit?: number, page?: number): Observable<Program[]> {
    return this.httpClient
      .get<Program[]>(this.path, { params: Utility.removeFalsyPropertiesFromHttpParams({limit, page}) })
      .pipe(
        map(response => response.map(program => new Program(program))),
        catchError(this.handleError),
      );
  }

  fetchProgramDetails(slug: string) {
    return this.httpClient
      .get<ProgramDetails>(`${this.path}/${slug}`)
      .pipe(catchError(this.handleError));
  }

  joinProgram(slug: string, delta?: number): Observable<JoinProgramResponse> {
    return this.httpClient
      .post<JoinProgramResponse>(`${this.path}/${slug}/join`, { delta: delta })
      .pipe(catchError(this.handleError));
  }

  leaveOrUnsubscribeProgram(
    token: string,
    type: 'email' | 'program',
  ): Observable<LeaveOrUnsubscribeProgramResponse | ErrorResponse> {
    return this.httpClient
      .get<LeaveOrUnsubscribeProgramResponse | ErrorResponse>(
        `${environment.apiPath}/v1/user/leave_or_unsubscribe_program`,
        { params: { pu_token: token, type } },
      )
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.message}`;
    }

    return throwError(errorMessage);
  }
}
