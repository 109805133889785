import { CommonModule } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from 'src/app/constants';
import { RatingTileComponent } from './rating-tile/rating-tile.component';

@Component({
  selector: 'app-ratings-carousel',
  standalone: true,
  imports: [CommonModule, RatingTileComponent, CarouselModule],
  templateUrl: './ratings-carousel.component.html',
  styleUrls: ['./ratings-carousel.component.sass'],
})
export class RatingsCarouselComponent {
  @Input() headline: string;
  private BREAKPOINTS = constants.breakpoints;

  public autoplayWithMargin: OwlOptions = {
    autoplay: false,
    loop: true,
    dots: true,
    dotsSpeed: 1200,
    autoplaySpeed: 1200,
    autoplayTimeout: 5000,
    nav: false,
    items: 1,
    responsive: {
      [this.BREAKPOINTS.xlarge]: {
        autoplay: false,
        loop: false,
        items: 3,
        margin: 16,
      },
    },
  };

  reviews = {
    de: [
      {
        author: 'Claudi via AppStore',
        headline: 'Einfach klasse',
        text: 'Die App ist einfach klasse. Immer finde ich etwas, was mich weiterbringt. Ich kann YogaEasy nur weiterempfehlen.',
      },
      {
        author: 'Petra via ausgezeichnet.org',
        headline: 'Vielfalt',
        text: 'Mir gefällt an YogaEasy, dass man verschiedene Yogastile kennenlernen und ausprobieren kann.',
      },
      {
        author: 'Erika via ausgezeichnet.org',
        headline: 'Perfekt!',
        text: 'Es ist ein besonderes Geschenk, die Lebensqualität mit YogaEasy zu erhöhen.',
      },
    ],
    en: [
      {
        author: 'Melissa',
        headline: 'Simply great',
        text: 'Subscribing to your platform has been the best choice for my mental, spiritual and physical health',
      },
      {
        author: 'Sam',
        headline: 'Wobbly',
        text: 'You make me feel okay that I wobble a bit and help me remember why I love yoga',
      },
      {
        author: 'Carole',
        headline: 'Perfect!',
        text: "I've lost count of the number of times I've recommended this online studio to others",
      },
    ],
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
