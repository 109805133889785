<section class="is-flex is-flex-direction-column has-background-cararra-mobile">
  <div class="has-background-e6f2f0 columns is-gapless">
    <span class="column is-7 is-hidden-mobile has-mb--6px-tablet">
      <img class="img-tablet-only is-width-100" ngSrc="production/uploads/components/mobile-app-section/app-banner-tablet.jpg"
        alt="Yogaeasy Apps" width="425" height="467" />
      <img class="img-desktop" ngSrc="production/uploads/components/mobile-app-section/app-banner-desktop.jpg" width="676" height="437"
        alt="Yogaeasy Apps" />
    </span>
    <div class="column is-5 is-flex is-flex-direction-column is-align-items-center pt-5-and-half">
      <ng-content></ng-content>
    </div>
  </div>
</section>