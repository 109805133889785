import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreActions } from '../core.actions';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.sass'],
})
export class MobileNavigationComponent {
  @Select(state => state.core.mobileNavigationOpen) mobileNavigationOpen$: Observable<string>;

  constructor(private store: Store) {}

  closeNav() {
    this.store.dispatch(new CoreActions.CloseMobileNavigation());
  }
}
