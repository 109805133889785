import {
  CommonModule,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import { Component } from '@angular/core';
import { ButtonAccentComponent } from '../button-accent/button-accent.component';

@Component({
  selector: 'mobile-app-section',
  standalone: true,
  imports: [CommonModule, ButtonAccentComponent, NgOptimizedImage],
  templateUrl: './mobile-app-section.component.html',
  styleUrls: ['./mobile-app-section.component.sass'],
  providers: [provideImageKitLoader('https://ik.imagekit.io/ye')],
})
export class MobileAppSectionComponent {}
