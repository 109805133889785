<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>Finde Verbindung in Live-Klassen und -Workshops</ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
    <ng-container i18n>
      Jede Woche kannst du in bis zu 7 Live-Klassen gemeinsam mit deinen Lieblingslehrer:innen und der YogaEasy-Community die Matte ausrollen.
      In Premium-Live-Workshops kannst du in besondere Themen zusätzlich tiefer einsteigen.
    </ng-container>
  </div>
  <div *ngIf="liveClassesBanner" class="is-position-relative is-flex is-justify-content-center">
    <img [ngSrc]="imageUrls.mobile" ngSrcset="343w, 686w" width="343" height="480" alt="Live Classes Banner"
      class="img-mobile has-border-radius-5" />
    <!-- without width: 100% on tablet and up Safari won't show the full resolution -->
    <img [ngSrc]="imageUrls.tablet" ngSrcset="730w, 1460w" width="730" height="320" alt="Live Classes Banner"
      class="img-tablet-only is-width-100 has-border-radius-5" />
    <img [ngSrc]="imageUrls.desktop" ngSrcset="1170w, 2340w" width="1170" height="234" alt="Live Classes Banner"
      class="img-desktop has-border-radius-5" />
    <ng-container *ngIf="isExternalLink; else internalBlock">
      <a [href]="liveClassesBanner.button_link" target="_blank"
        class="button is-outlined is-rounded is-medium has-text-weight-semibold is-position-absolute is-centered-horizontally-mobile is-bottom-32px-mobile is-bottom-36px-tablet is-right-61px-tablet px-6"
        [ngClass]="bannerButtonClass">{{ liveClassesBanner.button_label }}</a>
    </ng-container>

    <ng-template #internalBlock>
      <a [routerLink]="liveClassesBanner.button_link"
        class="button is-outlined is-rounded is-medium has-text-weight-semibold is-position-absolute is-centered-horizontally-mobile is-bottom-32px-mobile is-bottom-36px-tablet is-right-61px-tablet px-6"
        [ngClass]="bannerButtonClass">{{ liveClassesBanner.button_label }}</a>
    </ng-template>
  </div>
</section>
