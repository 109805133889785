<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>
      Entwickle dich weiter in der YogaEasy Academy
    </ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
    <ng-container i18n>
      Die YogaEasy Academy ist deine Plattform für hochwertige, zertifizierte Yoga-Ausbildungen und -Fortbildungen.
    </ng-container>
  </div>
  <div class="is-position-relative is-flex is-justify-content-center px-4-mobile">
    <a href="https://academy.yogaeasy.de/" target="_blank" rel="noopener">
      <img ngSrc="production/uploads/components/homepage/academy-section/academy-mobile.jpg" alt="Yogaeasy Academy" width="343" height="336"
        class="img-mobile has-border-radius-5" />
      <!-- without width: 100% on tablet and up, Safari won't show the full resolution -->
      <img ngSrc="production/uploads/components/homepage/academy-section/academy-tablet.jpg" alt="Yogaeasy Academy" width="730" height="383"
        class="img-tablet-only is-width-100 has-border-radius-5" />
      <img ngSrc="production/uploads/components/homepage/academy-section/academy-desktop.jpg" alt="Yogaeasy Academy" width="1170"
        height="300" class="img-desktop has-border-radius-5" />
    </a>
  </div>
</section>
