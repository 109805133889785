<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2" i18n>
    Mehr als nur on-demand Klassen
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5" i18n>
    Mit unseren Online Live-Klassen kannst du dich mit der YogaEasy Community verbinden. Um noch tiefer in die Yoga-Welt einzutauchen
    eigenen sich vor allem Workshops oder unsere Academy. Schau gerne mal rein.
  </div>
  @if(locale==='de'){
  <owl-carousel-o class="has-dots-under" [options]="autoplay">
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-mobile.jpg" width="343" height="320" alt="Live-Classes"
          class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-tablet.jpg" width="730" height="320" alt="Live-Classes"
          class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-desktop.jpg" width="1170" height="418" alt="Live-Classes"
          class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-fixed is-bottom-32px-desktop is-left-202px-desktop is-bottom-24px-mobile is-bottom-25px-tablet-only is-left-139px-tablet-only"
          type="primary" href="/live-klassen">Live-Klassen-Stundenplan</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/dashboard/slideshow/Workshops-mobile.jpg" width="343" height="320" alt="Premium-Workshops"
          class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Workshops-tablet.jpg" width="730" height="320" alt="Premium-Workshops"
          class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Workshops-desktop.jpg" width="1170" height="418"
          alt="Premium-Workshops" class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-fixed is-bottom-40px-desktop is-left-169px-desktop is-bottom-24px-mobile is-bottom-25px-tablet-only is-left-107px-tablet-only"
          type="primary" href="/live-klassen/#workshops">Workshops
          entdecken</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-mobile.jpg" width="343" height="320" alt="Academy"
          class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-tablet.jpg" width="730" height="320" alt="Academy"
          class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-desktop.jpg" width="1170" height="418" alt="Academy"
          class="img-desktop has-border-radius-5" />
        <div
          class="is-position-fixed is-bottom-64px-desktop is-right-187px-desktop is-bottom-24px-mobile is-bottom-25px-tablet-only is-right-150px-tablet-only">
          <app-button class="svg-button" type="primary" href="https://academy.yogaeasy.de/">YogaEasyAcademy </app-button>
          <svg-external-link
            class="is-position-absolute is-size-20px is-right-32px is-bottom-16px is-bottom-15px-tablet-only icon is-white"></svg-external-link>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
  }@else {
  <owl-carousel-o class="has-dots-under" [options]="autoplay">
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-mobile-EN.jpg" width="343" height="320"
          alt="Live-Classes" class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-tablet-EN.jpg" width="730" height="320"
          alt="Live-Classes" class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Live-classes-desktop-EN.jpg" width="1170" height="418"
          alt="Live-Classes" class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-fixed is-bottom-40px-desktop is-left-203px-desktop is-bottom-24px-mobile is-bottom-25px-tablet-only is-left-157px-tablet-only"
          type="primary" href="/live-klassen">Live classes schedule</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-mobile-EN.jpg" width="343" height="320" alt="Academy"
          class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-tablet-EN.jpg" width="730" height="320" alt="Academy"
          class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/dashboard/slideshow/Academy-desktop-EN.jpg" width="1170" height="418" alt="Academy"
          class="img-desktop has-border-radius-5" />
        <div
          class="is-position-fixed is-bottom-87px-desktop is-right-187px-desktop is-bottom-24px-mobile is-bottom-25px-tablet-only is-right-100px-tablet-only">
          <app-button class="svg-button" type="primary" href="https://academy.yogaeasy.de/">YogaEasyAcademy </app-button>
          <svg-external-link
            class="is-position-absolute is-size-20px is-right-32px is-bottom-16px is-bottom-15px-tablet-only icon is-white"></svg-external-link>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
  }
  <!-- make space for the dots -->
  <div class="mb-5">&nbsp;</div>
</section>